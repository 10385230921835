import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet dur en forma de crosta aferrat a la fusta o formant una sèrie de capellets semicirculars agrupats i que surten cap a fora del tronc. La part superior de color groguenc, amb tons entre taronja i marró, zonada, vellutada o hirsuta, amb un marge que sol ser de color groc viu, seguit d’una zona gris i peluda. La mida varia de 2-6 x 1-2 cm. Les espores són blanquinoses- grisoses, el·líptiques, allargades, hialines de 5-8 x 2-3,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      